<template>
  <div class="flex flex-col w-screen h-screen items-center justify-start">
    <div class="bg-background w-screen mb-10 pt-48 pb-5">
      <h1 class="text-3xl font-bold">Portal Login</h1>
    </div>
    <div class="flex justify-center bg-white w-screen">
      <BaseForm class="flex flex-col p-12 min-w-9/10 md:min-w-1/2 xl:min-w-1/3">
        <BaseInput
          id="email"
          v-model="email"
          :min="5"
          :max="100"
          :autofocus="true"
          :required="true"
          type="email"
          placeholder="Your Email"
          autocomplete="username"
          ref="email"
          :error="error"
          label="Email"
        />
        <div class="h-4 text-red mt-1">{{ errors.email }}</div>
        <BaseInput
          id="password"
          v-model="password"
          :min="5"
          :max="255"
          :required="true"
          type="password"
          placeholder="Your Password"
          :error="error"
          label="Password"
        />

        <!-- button -->
        <BaseButton class="mt-8 text-xl text-primary" @clicked="submit"
          >Log in</BaseButton
        >
      </BaseForm>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import router from "@/router";

import BaseInput from "@/components/BaseInput.vue";
import BaseForm from "@/components/BaseForm.vue";
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "login",
  components: {
    BaseButton,
    BaseInput,
    BaseForm,
  },
  data() {
    return {
      email: "",
      password: "",
      errors: {
        email: "",
        password: "",
      },
      error: "",
      valid: false,
    };
  },

  methods: {
    ...mapActions("auth", ["login"]),
    submit() {
      const payload = {
        username: this.email,
        password: this.password,
      };
      this.login(payload)
        .then((user) => {
          router.push({ name: "home" });
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            this.error = err.response.data.message;
          } else {
            this.error = "Unknown error occured";
          }
        });
    },
  },
};
</script>
