<template>
  <div class="flex flex-col w-screen h-screen items-center justify-start">
    <div class="bg-background w-screen mb-10 pt-48 pb-5">
      <h1 class="text-3xl font-bold">Portal Registration</h1>
    </div>
    <div class="flex justify-center bg-white w-screen">
      <BaseForm
        class="flex flex-col p-12 min-w-9/10 md:min-w-1/2 xl:min-w-1/3"
        v-if="!success"
      >
        <div class="flex justify-between">
          <BaseInput
            id="first_name"
            v-model="first_name"
            :min="5"
            :max="100"
            :autofocus="true"
            :required="true"
            type="text"
            placeholder="First Name"
            autocomplete="first_name"
            ref="first_name"
            :error="!first_error ? '' : errors.first_name"
            label="First Name"
            class="mr-2"
          />
          <BaseInput
            id="last_name"
            v-model="last_name"
            :min="5"
            :max="100"
            :autofocus="true"
            :required="true"
            type="text"
            placeholder="Last Name"
            autocomplete="username"
            ref="last_name"
            :error="!first_error ? '' : errors.last_name"
            label="Last Name"
            class="ml-2"
          />
        </div>
        <BaseInput
          id="email"
          v-model="email"
          :min="5"
          :max="100"
          :autofocus="true"
          :required="true"
          type="email"
          placeholder="Your Email"
          autocomplete="username"
          ref="email"
          :error="!first_error ? '' : errors.username"
          label="Email"
        />
        <BaseInput
          id="password"
          v-model="password"
          :min="5"
          :max="255"
          :required="true"
          type="password"
          placeholder="Your Password"
          :error="!first_error ? '' : errors.password"
          label="Password"
          autocomplete="new-password"
        />
        <BaseInput
          id="password2"
          v-model="password2"
          :min="5"
          :max="255"
          :required="true"
          type="password"
          placeholder="Your Password"
          :error="!first_error ? '' : error || errors.password2"
          label="Password"
          autocomplete="new-password"
        />

        <!-- button -->
        <BaseButton
          class="mt-8 text-xl text-primary"
          @clicked="submit"
          :disabled="notValidated"
          >Log in</BaseButton
        >
      </BaseForm>
      <div
        class="flex flex-col p-12 min-w-9/10 md:min-w-1/2 xl:min-w-1/3"
        v-if="success"
      >
        <div>
          Registration successful, wait for admin to approve your account.
        </div>
        <router-link to="/login">Go to login ➡️</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import router from "@/router";

import BaseInput from "@/components/BaseInput.vue";
import BaseForm from "@/components/BaseForm.vue";
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "register",
  components: {
    BaseButton,
    BaseInput,
    BaseForm,
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password2: "",
      first_error: false,
      errors: {
        last_name: "",
        last_name: "",
        email: "",
        password: "",
        password2: "",
      },
      error: "",
      valid: false,
      success: false,
    };
  },

  methods: {
    ...mapActions("auth", ["register"]),
    submit() {
      if (this.hasErrorsCheck()) return;
      const payload = {
        first_name: this.first_name,
        last_name: this.last_name,
        username: this.email,
        password: this.password,
      };
      this.register(payload)
        .then((user) => {
          this.success = true;
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            this.error = err.response.data.message;
          } else {
            this.error = "Unknown error occured";
          }
        });
    },
    hasErrorsCheck() {
      this.first_error = true;
      return this.validationError;
    },
  },
  computed: {
    validationError() {
      this.errors.first_name = !this.first_name ? "First Name Required" : "";
      this.errors.last_name = !this.last_name ? "Last Name Required" : "";
      this.errors.username = !this.email ? "Must be a valid email" : "";
      this.errors.password2 =
        this.password2 !== this.password ? "Passwords must match" : "";

      return Object.values(this.errors).some((error) => error.length);
    },
    notValidated() {
      return this.validationError;
    },
  },
};
</script>
